
::v-deep p {
    margin: 0;
}
.graphic-container {
    position: relative;
    /*height: calc(100vh - 100px);*/
    display: flex;
    flex-direction: column;
    .graphic-wrapper {
        display: flex;
        /*height: 1%;*/
        /*flex: 1;*/
        flex-direction: column;
        background: #fff;
        margin-top: 20px;
        border-radius: 10px;
        padding: 30px 40px;
        box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
        .tab-wrapper {
            .tab-item {
                display: inline-block;
                height: 32px;
                padding: 0 22px;
                line-height: 32px;
                background: #F1F0FE;
                color: #584EEE;
                font-size: 16px;
                margin-right: 20px;
                border-radius: 4px;
                transition: all .3s;
                &:last-child {
                    margin-right: 0;
                }
                &.current, &:hover {
                    color: #fff;
                    background: #574DED;
                }
            }
        }
        .graphic-info {
            .info-item {
                color: #16113F;
                display: flex;
                flex-wrap: wrap;
                margin-top: 10px;
                .left-box {
                    width: 40px;
                    margin-top: 20px;
                    line-height: 30px;
                }
                .right-box {
                    width: 1%;
                    flex: 1;
                    margin-left: 20px;
                    display: flex;
                    flex-wrap: wrap;
                    .round {
                        width: 38px;
                        height: 38px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #F1F0FE;
                        border-radius: 50%;
                        position: relative;
                        margin-right: 30px;
                        margin-top: 20px;
                        cursor: pointer;
                        .image {
                            max-width: 38px;
                            max-height: 38px;
                            border-radius: 50%;
                        }
                        .icon-box {
                            position: absolute;
                            bottom: 0;
                            right: -4px;
                            align-items: center;
                            justify-content: center;
                            width: 16px;
                            height: 16px;
                            background: #fff;
                            border-radius: 50%;
                            display: none;
                            .icon {
                                font-size: 16px;
                                color: #9ACC5B;
                                line-height: 1;
                            }
                        }
                        &.current {
                            .icon-box {
                                display: flex;
                            }
                        }
                    }
                    .square {
                        line-height: 30px;
                        margin-top: 20px;
                        margin-right: 30px;
                        padding: 0 10px;
                        border-radius: 5px;
                        cursor: pointer;
                        &.current {
                            color: #fff;
                            background: #9ACC5B;
                        }
                    }
                }
            }
        }
        .operate-wrapper {
            margin: 30px 0 10px;
        }
    }
}
.blue-text {
    font-weight: 500;
    color: #53C1F2;
}
.green-text {
    font-weight: 500;
    color: #67C23A;
}
.purple-text {
    font-weight: 500;
    color: #685EF3;
}
.red-text {
    font-weight: 500;
    color: #FB0338;
}
